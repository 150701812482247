import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Paper, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import { useNavigate } from "react-router-dom";

const carouselItems = [
    {
        title: "Saratoga After Upgrade",
        description: "Description goes here",
        photo: "/saratoga.jpg"
    },
    {
        title: "Cessna 182 After Upgrade",
        description: "Description goes here",
        photo: "/cessna182.jpg"
    },
    {
        title: "Commander 114TC After Upgrade",
        description: "Description goes here",
        photo: "/commander.jpg"
    }
];

const CarouselItem = ({item}) => {
    return (
        <Paper
            sx={{
                aspectRatio: '86 / 25',
                backgroundImage: `url(${item.photo})`,
                backgroundSize: 'cover',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly'
            }}
        >
            <Typography variant="h4" sx={{
                color: '#fff',
                textAlign: 'center',
                textShadow: '2px 2px 4px #000'
            }}>
                {item.title}
            </Typography>
            {/* <br/>
            <Typography>{item.description}</Typography>
            <Button>Check it out!</Button> */}
        </Paper>
    )
};

const cardLinkItems = [
    {
        title: "Home",
        description: "Welcome to Anderson Avionics in Anderson SC. We provide quality avionics installations, repairs and service to the Anderson area.",
        image: "/kand-flight.jpg",
        location: "../Home"
    },
    {
        title: "About",
        description: "Anderson Avionics provides quality avionics installations, repairs and service under one roof. We are a fully certified FAA Repair station.",
        image: "/art-portrait.jpg",
        location: "../About", 
        sx: {
            backgroundColor: '#434343',
            backgroundSize: 'contain'
        }
    },
    {
        title: "Location",
        description: "Anderson Avionics is located at the Anderson Airport (KAND) at 5825A Airport Road, Anderson, SC 29626",
        image: "/kand.png",
        location: "../Location"
    }
];

const CardLinkItem = ({item}) => {
    const navigate = useNavigate();

    return (
        <Card sx={{maxWidth: 260, display: 'flex', flexDirection: 'column'}}>
            <CardMedia
                sx={{height: 140, ...item.sx}}
                image={item.image}
            />
            <CardContent sx={{flexGrow: 1}}>
                <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                </Typography>
                <Typography variant="body2">
                    {item.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    onClick={() => navigate(item.location)}
                >
                    Read More
                </Button>
            </CardActions>
        </Card>
    );
};

const Home = () => {
    return (
        <Container maxWidth="md">
            <Carousel>
                {
                    carouselItems.map((item, index) => {
                        return <CarouselItem item={item} key={index} />
                    })
                }
            </Carousel>
            <Typography variant="h4">
                Welcome to Anderson Avionics of Anderson, South Carolina
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '1em',
                justifyContent: 'space-around',
                marginBottom: '1em'
            }}>
                {cardLinkItems.map((item, index) => {
                    return <CardLinkItem item={item} key={index} />
                })}
            </Box>
            <Typography variant="h4">
                Home
            </Typography>
            <Typography variant="h5">
                Welcome to Anderson Avionics at Anderson Airport (KAND)
            </Typography>
            <Typography variant="body1"
                sx={{
                    marginBottom: '1em'
                }}
            >
                We provide quality avionics installations, repairs and service to the Anderson area
                flying community. We are a Garmin Sales and Service Center and a fully certified 
                FAA Repair Station (CRS# V9JR020Y).
            </Typography>
            <Typography variant="h5">
                Our Mission Statement
            </Typography>
            <Typography variant="body1"
                sx={{
                    marginBottom: '1em'
                }}
            >
                We elevate the safety of flight and comfort of our clients with modern avionics
                upgrades and through superior maintenance procedures.
            </Typography>
        </Container>
    );
};

export default Home;