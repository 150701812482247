import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Container, Typography } from "@mui/material";

const ComingSoon = () => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const options = useMemo(
        () => ({
        background: {
            color: {
                value: "#0A3161",
            },
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: false,
                    mode: "push",
                },
                onHover: {
                    enable: false,
                    mode: "repulse",
                },
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 6,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 80,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
        },
        detectRetina: true,
        }),
        [],
    );

    if (init) {
        return (
            <>
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={options}
                />
                <Container zmaxWidth="sm"
                    sx={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 255, 255, 0.13)',
                        width: 'max-content',
                        maxWidth: '90%',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        left: '50%',
                        borderRadius: '1em',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        boxShadow: '0 0 40px rgba(8, 7, 16, 0.6)',
                        padding: '2em 1em',
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h4" sx={{color: '#FFF', paddingBottom: '1em'}}>
                        Coming Soon
                    </Typography>
                    <object width="96px" data="/logo.svg" type="image/svg+xml" aria-label='Company Logo'></object>
                    <Typography variant="h3" sx={{color: '#FFF', paddingBottom: '1em'}}>
                        Anderson Avionics
                    </Typography>
                    <Typography variant="body1" sx={{color: '#FFF'}}>
                        Stay Tuned
                    </Typography>
                </Container>
        </>
        );
    }

    return <></>;
};

export default ComingSoon;