import { Box, Container, IconButton, Link, Typography } from "@mui/material";
import XIcon from '@mui/icons-material/X';

const Footer = () => {
    const handleTwitterClick = () => {
        window.open('https://x.com/avionics_update', '_blank');
    };

    const handleBlueskyClick = () => {
        window.open('https://bsky.app/profile/anderson-avionics.com', '_blank');
    };

    return (
        <Box
            sx={{
                backgroundColor: "#1C1F24",
                color: "#C1C0C0",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Box sx={{backgroundColor: "#1C1F24", paddingTop: "1em", paddingBottom: "1em"}}>
                <Container maxWidth="md">
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Box>
                            <Typography variant="h5" gutterBottom>Anderson Avionics</Typography>
                            <Typography variant="body2" gutterBottom>
                                5825A Airport Road<br/>
                                Anderson, SC 29626
                            </Typography>
                            <Typography variant="body2">
                                Contact Number:<br/>
                                (510) 851-2236<br/>
                                <Link href="mailto:avionixusa@gmail.com" color="primary.light">avionixusa@gmail.com</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" gutterBottom>Affiliates</Typography>
                            <Typography variant="body2" gutterBottom>
                                <Link href="https://explore.garmin.com/en-US/general-aviation/" color="primary.light">Garmin</Link>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Link href="http://www.eapanels.com/" color="primary.light">Envision Avionic Panels</Link>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Link href="http://www.ps-engineering.com/" color="primary.light">PS Engineering Inc.</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" gutterBottom>Follow Us</Typography>
                            <IconButton aria-label="Follow us on X" color="lightPrimary" onClick={handleTwitterClick}>
                                <XIcon />
                            </IconButton>
                            <IconButton aria-label="Follow us on Bluesky" color="lightPrimary" onClick={handleBlueskyClick}>
                                <svg width="1em" height="1em" viewBox="0 0 600 530" version="1.1" xmlns="http://www.w3.org/2000/svg" class="size-4">
                                    <path d="m135.72 44.03c66.496 49.921 138.02 151.14 164.28 205.46 26.262-54.316 97.782-155.54 164.28-205.46 47.98-36.021 125.72-63.892 125.72 24.795 0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.3797-3.6904-10.832-3.7077-7.8964-0.0174-2.9357-1.1937 0.51669-3.7077 7.8964-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.4491-163.25-81.433-5.9562-21.282-16.111-152.36-16.111-170.07 0-88.687 77.742-60.816 125.72-24.795z"
                                        fill="currentColor"/>
                                </svg>
                            </IconButton>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={{backgroundColor: "#0F1114", paddingTop: "1em", paddingBottom: "1em"}}>
                <Container maxWidth="md">
                    <Typography variant="body2">{(new Date()).getFullYear()} All Rights Reserved</Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default Footer;