import { Outlet } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import { Box } from '@mui/material';

function App() {

  return (
    <Box className="App" sx={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
      <Header />
      <Box sx={{flexGrow: "1", marginBottom: "1em", marginTop: "1em"}}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
