import { Button, Container, Link, Typography } from "@mui/material";
import foreFlightIcon from '../foreflight-logo.png';

const Location = () => {
    const handleForeFlightClick = () => {
        window.location.href = "foreflightmobile://maps/search?q=D+KAND+34.4938N/82.714W";
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h2">
                Location
            </Typography>
            <Typography variant="body1">
                <strong>Anderson Avionics</strong> is located at the Anderson Airport (
                <Link href="https://www.airnav.com/airport/KAND">KAND</Link>
                )<br/>
                We are co-located with Heli-Plane Aviation Services.<br/>
                Look for the hangar with the Aircraft Maintenance sign.<br/><br/>
                5825A Airport Rd<br/>
                Anderson, SC 29626<br/>
                (650) 858-2000<br/>
                <br/>
                <Button 
                    variant="contained" 
                    endIcon={<img style={{height: "1.5em", width: "1.5em"}} src={foreFlightIcon} alt="ForeFlight Logo" />}
                    onClick={handleForeFlightClick}
                >
                    Find us in ForeFlight
                </Button>
                <br/>
                <br/>
                Hours:
                <br/>
                Mon-Fri 9:00 am - 5:00 pm<br/>
                Sat 9:00 am - 1:00 pm
            </Typography>
            <br/>
            <iframe
                title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d644.4343261417644!2d-82.71392367141951!3d34.49382116508653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sus!4v1732591147885!5m2!1sen!2sus" 
                width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                style={{
                    border:0,
                    width: '100%',
                    aspectRatio: "1"
                }} 
            >
            </iframe>
        </Container>
    );
};

export default Location;