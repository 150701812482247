import { Card, CardContent, CardMedia, Container, Typography } from "@mui/material";

const About = () => {
    return (
        <Container maxWidth="md">
            <Typography variant="h2">
                About
            </Typography>
            <Typography variant="body1"
                sx={{
                    marginBottom: "1em"
                }}
            >
                <strong>Anderson Avionics</strong> provides quality avionics installations, repairs
                and service under one roof. We are a fully certified FAA Repair Station (can 
                perform bi-annual transponder and Pitot static tests) and will fix anything that 
                has to do with the plane's electrical system, including instruments, instrument 
                panels, fuel flow systems and most auto-pilots.
            </Typography>
            <Typography variant="body1"
                sx={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    marginBottom: "1em"
                }}
            >
                We are a winner of Garmin "Leading Edge", a Silver Award and multiple Bronze Awards.
                Although we are a dealer for a multitude of Avionics manufacturers, we specialize 
                in Garmin Avionics simply because they are the best …
            </Typography>
            <Card sx={{marginBottom: "2em", maxWidth: "30em"}} >
                <CardMedia 
                    component="img"
                    sx={{ height: 254, width: 190, padding: "1em"}}
                    image="/art-portrait.jpg"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Art Vartanian
                    </Typography>
                    <Typography variant="body2">
                        Art Vartanian, the company Founder & CEO, holds a BSEE degree and has over 
                        20 years of experience. He is a member of the Aircraft Electronics 
                        Association and has an FCC License w/Radar Endorsement.
                    </Typography>
                </CardContent>
            </Card>
            <Typography variant="body1">
                You may ship your avionics equipment to us for evaluation, repairs or overhaul if 
                bringing them to us in person is impractical.<br/>
                <br/>
                We strive do our job right, the first time - every time, with utmost care and 
                professionalism. We do that for selfish reasons - we won't be able to sleep at 
                night otherwise …
            </Typography>
        </Container>
    );
};

export default About;